import InsightsPathConstants from '../../routes/InsightsPathConstants';

import CarbonMarket from "../../assets/Images/Insights/WhatsApp Image 2024-11-05 at 3.10.24 PM.jpeg";

import mainInsightsArbitrationResolvingImage from '../../assets/Images/Insights/main-insights-arbitration-resolving.jpeg';
import insightsArbitration from '../../assets/Images/Insights/taxing-catalyst-arbitration.jpeg';
import insightsAdaptation from '../../assets/Images/Insights/taxing-catalyst-adaptation.jpeg';
import ArbitrationResolvingText from './InsightsTexts/ArbitrationResolving/ArbitrationResolvingText';
import ArbitrationResolvingHeaderText from './InsightsTexts/ArbitrationResolving/ArbitrationHeaderText';

import AviationText from './InsightsTexts/Aviation/AviationText';
import AviationHeaderText from './InsightsTexts/Aviation/AviationHeaderText';

import mainInsightsRenewable from './aniek-wessel-cuTTK-G9LOc-unsplash.jpg';

// import mainInsightsCapitalRetentionImage from '../../assets/Images/Insights/main-insights-capital-retention.jpeg';
import mainInsightsLawLaborImage from '../../assets/Images/Insights/main-insights-law-labor.jpeg';
import mainInsightsFinanceImage from '../../assets/Images/Insights/insights-finance.jpeg';
import CapitalRetentionText, { CarbonMarketHeaderText, CarbonMarketText } from './InsightsTexts/CapitalRetention/CapitalRetentionText';
import CapitalRetentionHeaderText from './InsightsTexts/CapitalRetention/CapitalRetentionHeaderText';

import mainInsightsGenericInsightsRenewableImage from './geranimo-qzgN45hseN0-unsplash.jpg';
import conferenceOfThePartiesInsightsWaves from '../../assets/Images/Insights/renewable-energy-waves.jpeg';
import conferenceOfThePartiesInsightsGreen from '../../assets/Images/Insights/renewable-energy-green.jpeg';
import ConferenceOfThePartiesText from './InsightsTexts/ConferenceOfTheParties/ConferenceOfThePartiesText';
import ConferenceOfThePartiesHeaderText from './InsightsTexts/ConferenceOfTheParties/ConferenceOfThePartiesHeaderText';

// import mainInsightsConstructionTrendsImage from '../../assets/Images/Insights/main-insights-construction-trends.jpeg';
import constructionTrendsSmartConstruction from '../../assets/Images/Insights/insights-smart-construction.jpeg';
import constructionTrendsAugmentedReality from '../../assets/Images/Insights/insights-augmented-reality.jpeg';
import ConstructionTrendsText from './InsightsTexts/ConstructionTrends/ConstructionTrendsText';
import ConstructionTrendsHeaderText from './InsightsTexts/ConstructionTrends/ConstructionTrendsHeaderText';

import mainInsightsCrcicaRulesImage from './willian-justen-de-vasconcellos-9M9YMjwOvcU-unsplash.jpg';
import crciraRulesAdaptation from '../../assets/Images/Insights/taxing-catalyst-adaptation.jpeg';
import crciraRulesArbitration from '../../assets/Images/Insights/taxing-catalyst-arbitration.jpeg';
import CrcicaRulesText from './InsightsTexts/CRCICARules/CrcicaRulesText';
import CrcicaRulesHeaderText from './InsightsTexts/CRCICARules/CrcicaRulesHeaderText';

import mainInsightsDataPrivacyImage from './data.jpg';
import dataPrivacyInsightAi from '../../assets/Images/Insights/data-privacy-ai.jpeg';
import dataPrivacyInsightConnectivity from '../../assets/Images/Insights/data-privacy-connectivity.jpeg';
import DataPrivacyText from './InsightsTexts/DataPrivacy/DataPrivacyText';
import DataPrivacyHeaderText from './InsightsTexts/DataPrivacy/DataPrivacyHeaderText';

import mainInsightsEnergyGlobalDemandImage from '../../assets/Images/Insights/insights-energy-global-demand.jpeg';
import insightsElectrificationImage from '../../assets/Images/Insights/insights-electrification.jpeg';
import insightsCircularEnergyImage from '../../assets/Images/Insights/insights-circular-energy.jpeg';
import EnergyGlobalDemandText from './InsightsTexts/EnergyGlobalDemand/EnergyGlobalDemandText';
import EnergyGlobalDemandHeaderText from './InsightsTexts/EnergyGlobalDemand/EnergyGlobalDemandHeaderText';

import mainInsightsGafiImage from './shubham-dhage-BfERfOjtd-E-unsplash.jpg';
import insightsAiImage from '../../assets/Images/Insights/data-privacy-ai.jpeg';
import insightsConnectivityImage from '../../assets/Images/Insights/data-privacy-connectivity.jpeg';
import GafiText from './InsightsTexts/GAFI/GafiText';
import GafiHeaderText from './InsightsTexts/GAFI/GafiHeaderText';

import mainInsightsHydrogenInitiativesImage from '../../assets/Images/Insights/main-insights-hydrogen-initiatives.jpeg';
import insightsHydrogenInitiativesImage from '../../assets/Images/Insights/renewable-energy-waves.jpeg';
import insightsGreenImage from '../../assets/Images/Insights/renewable-energy-green.jpeg';
import HydrogenInitiativesText from './InsightsTexts/HydrogenInitiatives/HydrogenInitiativesText';
import HydrogenInitiativesHeaderText from './InsightsTexts/HydrogenInitiatives/HydrogenInitiativesHeaderText';

import mainInsightsInternationalFinanceImage from './scott-webb-sBzVE77Bglc-unsplash.jpg';
import InternationalFinanceText from './InsightsTexts/InternationalFinance/InternationalFinanceText';
import InternationalFinanceHeaderText from './InsightsTexts/InternationalFinance/InternationalFinanceHeaderText';

import mainInsightsLaborLawImage from '../../assets/Images/Insights/main-insights-law-labor.jpeg';
import laborLawInsightsHybrid from '../../assets/Images/Insights/labor-law-insights-hybrid.jpeg';
import laborLawInsightsBenefits from '../../assets/Images/Insights/labor-law-insights-1.jpeg';
import LaborLawText from './InsightsTexts/LaborLaw/LaborLawText';
import LaborLawHeaderText from './InsightsTexts/LaborLaw/LaborLawHeaderText';
import mainInsightsCapitalFunds from './tobias-keller-2ecH5Lw3zSk-unsplash.jpg';

import mainInsightsLegalPerspectiveImage from '../../assets/Images/Insights/main-insights-legal-perspective.jpeg';
import legalPerspectiveAdaptation from '../../assets/Images/Insights/taxing-catalyst-adaptation.jpeg';
import legalPerspectiveArbitration from '../../assets/Images/Insights/taxing-catalyst-arbitration.jpeg';
import LegalPerspectiveText from './InsightsTexts/LegalPerspective/LegalPerspectiveText';
import LegalPerspectiveHeaderText from './InsightsTexts/LegalPerspective/LegalPerspectiveHeaderText';

import RenewableEnergyInsightsWaves from '../../assets/Images/Insights/renewable-energy-waves.jpeg';
import RenewableEnergyInsightsGreen from '../../assets/Images/Insights/renewable-energy-green.jpeg';
import RenewableEnergyText from './InsightsTexts/RenewableEnergy/RenewableEnergyText';
import RenewableEnergyHeaderText from './InsightsTexts/RenewableEnergy/RenewableEnergyHeaderText';

import mainInsightsRenewableEnergyFutureImage from '../../assets/Images/Insights/main-insights-renewable-energy-future.jpeg';
import renewableEnergyFutureWaves from '../../assets/Images/Insights/renewable-energy-waves.jpeg';
import renewableEnergyFutureGreen from '../../assets/Images/Insights/renewable-energy-green.jpeg';
import RenewableEnergyFutureText from './InsightsTexts/RenewableEnergyFuture/RenewableEnergyFutureText';
import RenewableEnergyFutureHeaderText from './InsightsTexts/RenewableEnergyFuture/RenewableEnergyFutureHeaderText';

import mainInsightsSustainableMobilityImage from '../../assets/Images/Insights/main-insights-green-transportation.jpeg';
import sustainableMobilityMaritime from '../../assets/Images/Insights/sustainable-mobility-maritime.jpeg';
import sustainableMobilityInfrastructure from '../../assets/Images/Insights/sustainable-mobility-infrastructure.jpeg';
import SustainableMobilityText from './InsightsTexts/SustainableMobility/SustainableMobilityText';
import SustainableMobilityHeaderText from './InsightsTexts/SustainableMobility/SustainableMobilityHeaderText';

import mainInsightsTaxingCatalystImage from '../../assets/Images/Insights/main-insights-taxing-catalyst.jpeg';
import taxingCatalystAdaptation from '../../assets/Images/Insights/taxing-catalyst-adaptation.jpeg';
import taxingCatalystArbitration from '../../assets/Images/Insights/taxing-catalyst-arbitration.jpeg';
import TaxingCatalystText from './InsightsTexts/TaxingCatalyst/TaxingCatalystText';
import TaxingCatalystHeaderText from './InsightsTexts/TaxingCatalyst/TaxingCatalystHeaderText';

// import mainInsightsTransportationLogisticsImage from '../../assets/Images/Insights/main-insights-international-finance.jpeg';
import insightsMaritime from '../../assets/Images/Insights/sustainable-mobility-maritime.jpeg';
import insightsInfrastructure from '../../assets/Images/Insights/sustainable-mobility-infrastructure.jpeg';
import TransportationLogisticsText from './InsightsTexts/TransportationLogistics/TransportationLogisticsText';
import TransportationLogisticsHeaderText from './InsightsTexts/TransportationLogistics/TransportationLogisticsHeaderText';

import mainInsightsSustainableFuelsImage from '../../assets/Images/Insights/main-insights-sustainable-fuels.jpeg';
import insightsAdaptationImage from '../../assets/Images/Insights/taxing-catalyst-adaptation.jpeg';
import insightsArbitrationImage from '../../assets/Images/Insights/taxing-catalyst-arbitration.jpeg';
import SustainableFuelsText from './InsightsTexts/SustainableFuels/SustainableFuelsText';
import SustainableFuelsHeaderText from './InsightsTexts/SustainableFuels/SustainableFuelsHeaderText';

import mainInsightsMergerControlImage from '../../assets/Images/Insights/insights-merger-control.jpeg';
import mergerControlAdaptation from '../../assets/Images/Insights/taxing-catalyst-adaptation.jpeg';
import mergerControlArbitration from '../../assets/Images/Insights/taxing-catalyst-arbitration.jpeg';
import MergerControlText from './InsightsTexts/MergerControl/MergerControlText';
import MergerControlHeaderText from './InsightsTexts/MergerControl/MergerControlHeaderText';
import mainInsightsTransportationLogistics from '../../assets/Images/Insights/main-insights-transportation.jpeg';

import mainInsightsConstruction from '../../assets/Images/Insights/main-insights-construction.jpeg';
import mainAviation from './Group 119.png';

import CarbomMarketPicture from "../../assets/Images/Insights/WhatsApp Image 2024-11-05 at 3.10.24 PM.jpeg";



export const InsightsInfo = {
  aviation: {
    heroDetails: {
      heroImage: mainAviation,
      heroPrimaryHeading: 'Insights',
      heroSecondaryHeading: 'Insights',
      heroSubHeading:
        'EU Adopts New Law to Decarbonize Aviation Sector with Sustainable Fuels',
    },
    insightInfo: <AviationText />,
    profiles: [
      'amal-afifi',
      'hossam-el-sharawi',
      'ahmed-rasheed',
      'andrew-afifi',
      'alia-safei',
    ],
    teamName: 'Aviation',
    headertext: <AviationHeaderText />,
  },
  arbitrationResolving: {
    heroDetails: {
      heroImage: mainInsightsArbitrationResolvingImage,
      heroPrimaryHeading: 'Insights',
      heroSecondaryHeading: 'Insights',
      heroSubHeading:
        'The Rise of Arbitration Resolving International Finance Disputes with Speed and Expertise',
    },
    relatedInsights: [
      {
        img: insightsAdaptation,
        title: 'Adaptions',
        textLines: [
          'Arbitration',
          'Adaptions in Arbitration',
          'Rules',
          '31 January, 2024',
        ],
      },
      {
        img: insightsArbitration,
        title: 'Arbitration',
        textLines: [
          'Arbitration',
          'Arbitration in Specifc',
          'Sectors',
          '31 January, 2024',
        ],
      },
    ],
    insightInfo: <ArbitrationResolvingText />,
    teamName: 'Arbitration',
    headertext: <ArbitrationResolvingHeaderText />,
    profiles: [],
  }, //ok
  capitalRetention: {
    heroDetails: {
      heroImage: mainInsightsCapitalFunds,
      heroPrimaryHeading: 'Insights',
      heroSecondaryHeading: 'Insights',
      heroSubHeading:
        'Egypt’s New Opportunities: Embracing Foreign Currency Capital Retention',
    },
    relatedInsights: [
      {
        img: mainInsightsLawLaborImage,
        title: 'Banking & Finance',
        textLines: [
          'Banking & Finance',
          'Digital',
          'Banking Transformation',
          '31 January, 2024',
        ],
      },
      {
        img: mainInsightsFinanceImage,
        title: 'Green Finance',
        textLines: [
          'Banking & Finance',
          'Sustainable',
          'and Green Finance',
          '31 January, 2024',
        ],
      },
    ],
    insightInfo: <CapitalRetentionText />,
    teamName: 'Banking & Finance',
    headertext: <CapitalRetentionHeaderText />,
    profiles: ['mohamed-tayel', 'emil-ghobrial'],
  }, //ok
  conferenceOfTheParties: {
    heroDetails: {
      heroImage: mainInsightsGenericInsightsRenewableImage,
      heroPrimaryHeading: 'Insights',
      heroSecondaryHeading: 'Insights',
      heroSubHeading:
        "Navigating the Path to Renewables: Egypt and the UAE's Energy Transition Roadmap at COP27 and COP28",
    },
    relatedInsights: [
      {
        img: conferenceOfThePartiesInsightsWaves,
        title: 'Wave and Tidal',
        textLines: [
          'Renewable Energy',
          'Wave and Tidal',
          'Energy',
          '31 January, 2024',
        ],
      },
      {
        img: conferenceOfThePartiesInsightsGreen,
        title: 'Employment',
        textLines: [
          'Renewable Energy',
          'Green Hydrogen',
          'Production',
          '31 January, 2024',
        ],
      },
    ],
    insightInfo: <ConferenceOfThePartiesText />,
    profiles: ['mohamed-tayel', 'andrew-afifi'],
    teamName: 'Renewable Energy',
    headertext: <ConferenceOfThePartiesHeaderText />,
  },
  constructionTrends: {
    heroDetails: {
      heroImage: mainInsightsConstruction,
      heroPrimaryHeading: 'Insights',
      heroSecondaryHeading: 'Insights',
      heroSubHeading:
        "Charting the Future of Concrete and Steel Emerging Trends and Untapped Potential in Egypt's Construction Landscape by 2025",
    },
    relatedInsights: [
      {
        img: constructionTrendsSmartConstruction,
        title: 'Smart Construction',
        textLines: [
          'Construction',
          'Smart Construction',
          'Technologies',
          '31 January, 2024',
        ],
      },
      {
        img: constructionTrendsAugmentedReality,
        title: 'Augmented Reality',
        textLines: [
          'Construction',
          'Augmented Reality in',
          'Construction',
          '31 January, 2024',
        ],
      },
    ],
    insightInfo: <ConstructionTrendsText />,
    teamName: 'Construction',
    headertext: <ConstructionTrendsHeaderText />,
    profiles: ['amal-afifi', 'mahrous-shafik', 'tamer-kamal'],
  },
  crcicaRules: {
    heroDetails: {
      heroImage: mainInsightsCrcicaRulesImage,
      heroPrimaryHeading: 'Insights',
      heroSecondaryHeading: 'Insights',
      heroSubHeading:
        'CRCICA Rules 2024: Advancing International Arbitration',
    },
    relatedInsights: [
      {
        img: crciraRulesAdaptation,
        title: 'Adaptions',
        textLines: [
          'Arbitration',
          'Adaptions in Arbitration',
          'Rules',
          '31 January, 2024',
        ],
      },
      {
        img: crciraRulesArbitration,
        title: 'Arbitration',
        textLines: [
          'Arbitration',
          'Arbitration in Specifc',
          'Sectors',
          '31 January, 2024',
        ],
      },
    ],
    insightInfo: <CrcicaRulesText />,
    teamName: 'Arbitration',
    headertext: <CrcicaRulesHeaderText />,
    profiles: ['amal-afifi', 'tamer-kamal'],
  },
  dataPrivacy: {
    heroDetails: {
      heroImage: mainInsightsDataPrivacyImage,
      heroPrimaryHeading: 'Insights',
      heroSecondaryHeading: 'Insights',
      heroSubHeading:
        "A New Dawn for Data Privacy: Unveiling Egypt's Evolving Regulatory Landscape",
    },
    relatedInsights: [
      {
        img: dataPrivacyInsightAi,
        title: 'AI',
        textLines: [
          'Technology',
          'Artifical Intelligence',
          'Advancements',
          '31 January, 2024',
        ],
      },
      {
        img: dataPrivacyInsightConnectivity,
        title: 'Connectivity',
        textLines: [
          'Technology',
          '5G and Advanced',
          'Connectivity',
          '31 January, 2024',
        ],
      },
    ],
    insightInfo: <DataPrivacyText />,
    profiles: ['amal-afifi', 'mohamed-tayel', 'ahmed-rasheed', 'andrew-afifi'],
    teamName: 'Technology',
    headertext: <DataPrivacyHeaderText />,
  },
  energyGlobalDemand: {
    heroDetails: {
      heroImage: mainInsightsEnergyGlobalDemandImage,
      heroPrimaryHeading: 'Insights',
      heroSecondaryHeading: 'Insights',
      heroSubHeading:
        "Egypt's Strategic Optimization for a Dynamic Global Energy Market",
    },
    relatedInsights: [
      {
        img: insightsCircularEnergyImage,
        title: 'Electrification',
        textLines: [
          'Energy',
          'Electrification of',
          'Everything',
          '31 January, 2024',
        ],
      },
      {
        img: insightsElectrificationImage,
        title: 'Circular Energy',
        textLines: ['Energy', 'Circular Economy', 'Energy', '31 January, 2024'],
      },
    ],
    insightInfo: <EnergyGlobalDemandText />,
    teamName: 'Energy',
    headertext: <EnergyGlobalDemandHeaderText />,
    profiles: ['amal-afifi', 'andrew-afifi'],
  },
  gafi: {
    heroDetails: {
      heroImage: mainInsightsGafiImage,
      heroPrimaryHeading: 'Insights',
      heroSecondaryHeading: 'Insights',
      heroSubHeading:
        'GAFI Streamlines Post-Incorporation Services',
    },
    relatedInsights: [
      {
        img: insightsAiImage,
        title: 'AI',
        textLines: [
          'Technology',
          'Artifical Intelligence',
          'Advancements',
          '31 January, 2024',
        ],
      },
      {
        img: insightsConnectivityImage,
        title: 'Connectivity',
        textLines: [
          'Technology',
          '5G and Advanced',
          'Connectivity',
          '31 January, 2024',
        ],
      },
    ],
    insightInfo: <GafiText />,
    teamName: 'Technology',
    headertext: <GafiHeaderText />,
    profiles: ['emil-ghobrial', 'mohamed-tayel', 'alia-safei'],
  },
  internationalFinance: {
    heroDetails: {
      heroImage: mainInsightsInternationalFinanceImage,
      heroPrimaryHeading: 'Insights',
      heroSecondaryHeading: 'Insights',
      heroSubHeading:
        "International Finance Corporation Injects USD 7 Billion into Egypt's Economic Engine",
    },
    relatedInsights: [
      {
        img: mainInsightsLawLaborImage,
        title: 'Banking & Finance',
        textLines: [
          'Banking & Finance',
          'Digital',
          'Banking Transformation',
          '31 January, 2024',
        ],
      },
      {
        img: mainInsightsFinanceImage,
        title: 'Green Finance',
        textLines: [
          'Banking & Finance',
          'Sustainable',
          'and Green Finance',
          '31 January, 2024',
        ],
      },
    ],
    insightInfo: <InternationalFinanceText />,
    teamName: 'Banking & Finance',
    headertext: <InternationalFinanceHeaderText />,
    profiles: [
      "ahmed-rasheed", "andrew-afifi", "alia-safei"
    ],
  },
  laborLaw: {
    heroDetails: {
      heroImage: mainInsightsLaborLawImage,
      heroPrimaryHeading: 'Insights',
      heroSecondaryHeading: 'Insights',
      heroSubHeading:
        "A Balancing Act: Egypt’s New Labor Law Navigates a Complex Landscape",
    },
    relatedInsights: [
      {
        img: laborLawInsightsHybrid,
        title: 'Hybrid Work',
        textLines: ['Employment', 'Hybrid Work', 'Models', '31 January, 2024'],
      },
      {
        img: laborLawInsightsBenefits,
        title: 'Employment',
        textLines: [
          'Employment',
          'Flexible Benefits',
          'Packages',
          '31 January, 2024',
        ],
      },
    ],
    insightInfo: <LaborLawText />,
    teamName: 'Employment',
    headertext: <LaborLawHeaderText />,
    profiles: [],
  }, //ok
  legalPerspective: {
    heroDetails: {
      heroImage: mainInsightsLegalPerspectiveImage,
      heroPrimaryHeading: 'Insights',
      heroSecondaryHeading: 'Insights',
      heroSubHeading: 'New International Arbitration Centre Launches in Abu Dhabi (ArbitrateAD)',
    },
    relatedInsights: [
      {
        img: legalPerspectiveAdaptation,
        title: 'Adaptions',
        textLines: [
          'Arbitration',
          'Adaptions in Arbitration',
          'Rules',
          '31 January, 2024',
        ],
      },
      {
        img: legalPerspectiveArbitration,
        title: 'Arbitration',
        textLines: [
          'Arbitration',
          'Arbitration in Specifc',
          'Sectors',
          '31 January, 2024',
        ],
      },
    ],
    insightInfo: <LegalPerspectiveText />,
    teamName: 'Arbitration',
    headertext: <LegalPerspectiveHeaderText />,
    profiles: ['amal-afifi', 'tamer-kamal'],
  },
  renewableEnergy: {
    heroDetails: {
      heroImage: mainInsightsRenewable,
      heroPrimaryHeading: 'Insights',
      heroSecondaryHeading: 'Insights',
      heroSubHeading:
        "A Nation Powered by Sunshine Egypt's 2030 Renewable Energy Target",
    },
    relatedInsights: [
      {
        img: RenewableEnergyInsightsWaves,
        title: 'Wave and Tidal',
        textLines: [
          'Renewable Energy',
          'Wave and Tidal',
          'Energy',
          '31 January, 2024',
        ],
      },
      {
        img: RenewableEnergyInsightsGreen,
        title: 'Employment',
        textLines: [
          'Renewable Energy',
          'Green Hydrogen',
          'Production',
          '31 January, 2024',
        ],
      },
    ],
    insightInfo: <RenewableEnergyText />,
    teamName: 'Renewable Energy',
    headertext: <RenewableEnergyHeaderText />,
    profiles: [],
  },
  renewableEnergyFuture: {
    heroDetails: {
      heroImage: mainInsightsRenewableEnergyFutureImage,
      heroPrimaryHeading: 'Insights',
      heroSecondaryHeading: 'Insights',
      heroSubHeading:
        'CRCICA Rules 2024: Advancing International Arbitration',
    },
    relatedInsights: [
      {
        img: renewableEnergyFutureWaves,
        title: 'Wave and Tidal',
        textLines: [
          'Renewable Energy',
          'Wave and Tidal',
          'Energy',
          '31 January, 2024',
        ],
      },
      {
        img: renewableEnergyFutureGreen,
        title: 'Employment',
        textLines: [
          'Renewable Energy',
          'Green Hydrogen',
          'Production',
          '31 January, 2024',
        ],
      },
    ],
    insightInfo: <RenewableEnergyFutureText />,
    teamName: 'Renewable Energy',
    headertext: <RenewableEnergyFutureHeaderText />,
    profiles: ["amal-afifi", "tamer-kamal"]
  },
  sustainableMobility: {
    heroDetails: {
      heroImage: mainInsightsSustainableMobilityImage,
      heroPrimaryHeading: 'Insights',
      heroSecondaryHeading: 'Insights',
      heroSubHeading:
        'Green Transportation: Navigating the Transition to Sustainable Mobility',
    },
    relatedInsights: [
      {
        img: sustainableMobilityMaritime,
        title: 'Maritime',
        textLines: [
          'Transportation',
          'Maritime Transportation',
          'Advances',
          '31 January, 2024',
        ],
      },
      {
        img: sustainableMobilityInfrastructure,
        title: 'Infrastructure',
        textLines: [
          'Transportation',
          'Transportation',
          'Infrastructure Renewal',
          '31 January, 2024',
        ],
      },
    ],
    insightInfo: <SustainableMobilityText />,
    profiles: ['mohamed-tayel', 'andrew-afifi', 'alia-safei'],
    teamName: 'Transportation',
    headertext: <SustainableMobilityHeaderText />,
  },
  taxingCatalyst: {
    heroDetails: {
      heroImage: mainInsightsTaxingCatalystImage,
      heroPrimaryHeading: 'Insights',
      heroSecondaryHeading: 'Insights',
      heroSubHeading: 'Taxation and Investment Law: A Taxing Catalyst',
    },
    relatedInsights: [
      {
        img: taxingCatalystAdaptation,
        title: 'Adaptions',
        textLines: [
          'Arbitration',
          'Adaptions in Arbitration',
          'Rules',
          '31 January, 2024',
        ],
      },
      {
        img: taxingCatalystArbitration,
        title: 'Arbitration',
        textLines: [
          'Arbitration',
          'Arbitration in Specifc',
          'Sectors',
          '31 January, 2024',
        ],
      },
    ],
    insightInfo: <TaxingCatalystText />,
    profiles: ['amal-afifi', 'fady-seif', 'walid-sakr', 'mohamed-tayel'],
    teamName: 'Arbitration',
    headertext: <TaxingCatalystHeaderText />,
  },
  transportationLogistics: {
    heroDetails: {
      heroImage: mainInsightsTransportationLogistics,
      heroPrimaryHeading: 'Insights',
      heroSecondaryHeading: 'Insights',
      heroSubHeading:
        "Ascending the Logistics Ladder: Egypt's Strategic Transformation into a Global Transport and Logistics Hub by 2024",
    },
    relatedInsights: [
      {
        img: insightsMaritime,
        title: 'Maritime',
        textLines: [
          'Transportation',
          'Maritime Transportation',
          'Advances',
          '31 January, 2024',
        ],
      },
      {
        img: insightsInfrastructure,
        title: 'Infrastructure',
        textLines: [
          'Transportation',
          'Transportation',
          'Infrastructure Renewal',
          '31 January, 2024',
        ],
      },
    ],
    insightInfo: <TransportationLogisticsText />,
    teamName: 'Transportation',
    headertext: <TransportationLogisticsHeaderText />,
    profiles: ['gamil-dorgham', 'tamer-kamal', 'ahmed-rasheed'],
  },
  sustainableFuels: {
    heroDetails: {
      heroImage: mainInsightsSustainableFuelsImage,
      heroPrimaryHeading: 'Insights',
      heroSecondaryHeading: 'Insights',
      heroSubHeading:
        "Egypt's Strategic Optimization for a Dynamic Global Energy Market",
    },
    relatedInsights: [
      {
        img: insightsAdaptationImage,
        title: 'Adaptions',
        textLines: [
          'Arbitration',
          'Adaptions in Arbitration',
          'Rules',
          '31 January, 2024',
        ],
      },
      {
        img: insightsArbitrationImage,
        title: 'Arbitration',
        textLines: [
          'Arbitration',
          'Arbitration in Specifc',
          'Sectors',
          '31 January, 2024',
        ],
      },
    ],
    insightInfo: <SustainableFuelsText />,
    profiles: ['habil-abu-el-kheir', 'tamer-kamal', 'hossam-el-sharawi'],
    teamName: 'Arbitration',
    headertext: <SustainableFuelsHeaderText />,
  },
  mergerControl: {
    heroDetails: {
      heroImage: mainInsightsMergerControlImage,
      heroPrimaryHeading: 'Insights',
      heroSecondaryHeading: 'Insights',
      heroSubHeading:
        "Reshaping the Landscape: Transformative Changes in Egypt’s Merger Control Framework",
    },
    relatedInsights: [
      {
        img: mergerControlAdaptation,
        title: 'Adaptions',
        textLines: [
          'Arbitration',
          'Adaptions in Arbitration',
          'Rules',
          '31 January, 2024',
        ],
      },
      {
        img: mergerControlArbitration,
        title: 'Arbitration',
        textLines: [
          'Arbitration',
          'Arbitration in Specifc',
          'Sectors',
          '31 January, 2024',
        ],
      },
    ],
    insightInfo: <MergerControlText />,
    profiles: ['ahmed-rasheed', 'andrew-afifi'],
    teamName: 'Arbitration',
    headertext: <MergerControlHeaderText />,
  },
  hydrogenInitiative: {
    heroDetails: {
      heroImage: mainInsightsHydrogenInitiativesImage,
      heroPrimaryHeading: 'Insights',
      heroSecondaryHeading: 'Insights',
      heroSubHeading:
        'Legislative Committee Approves Proposed Law on Incentives for Green Hydrogen Initiatives',
    },
    relatedInsights: [
      {
        img: insightsHydrogenInitiativesImage,
        title: 'Wave and Tidal',
        textLines: [
          'Renewable Energy',
          'Wave and Tidal',
          'Energy',
          '31 January, 2024',
        ],
      },
      {
        img: insightsGreenImage,
        title: 'Employment',
        textLines: [
          'Renewable Energy',
          'Green Hydrogen',
          'Production',
          '31 January, 2024',
        ],
      },
    ],
    insightInfo: <HydrogenInitiativesText />,
    profiles: ['amal-afifi', 'andrew-afifi'],
    teamName: 'Renewable Energy',
    headertext: <HydrogenInitiativesHeaderText />,
  },
  carbonMarket: {
    heroDetails: {
      heroImage: CarbomMarketPicture,
      heroPrimaryHeading: 'Insights',
      heroSecondaryHeading: 'Insights',
      heroSubHeading:
        "Egypt's New Regulated Voluntary Carbon Market",
    },
    relatedInsights: [
      {
        img: insightsHydrogenInitiativesImage,
        title: 'Wave and Tidal',
        textLines: [
          'Renewable Energy',
          'Wave and Tidal',
          'Energy',
          '31 January, 2024',
        ],
      },
      {
        img: insightsGreenImage,
        title: 'Employment',
        textLines: [
          'Renewable Energy',
          'Green Hydrogen',
          'Production',
          '31 January, 2024',
        ],
      },
    ],
    insightInfo: <CarbonMarketText/>,
    profiles: [],
    teamName: 'Renewable Energy',
    headertext: <CarbonMarketHeaderText/>,
  },
};

export const RelatedInsightsImages = [
  // {
  //   img: mainInsightsArbitrationResolvingImage,
  //   title: 'Adaptions',
  //   textLines: [
  //     'Arbitration Resolving',
  //     'The Rise of Arbitration Resolving International Finance Disputes with Speed and Expertise',
  //     '15 December, 2023',
  //   ],
  //   toPage: InsightsPathConstants.ARBITRATION_RESOLVING,
  // },
  // {
  //   img: mainInsightsLaborLawImage,
  //   title: 'Banking & Finance',
  //   textLines: [
  //     'Labor Law',
  //     "A Balancing Act: Egypt’s New Labor Law Navigates a Complex Landscape",
  //     '10 September, 2023',
  //   ],
  //   toPage: InsightsPathConstants.LABOR_LAW,
  // },
  // {
  //   img: mainInsightsInternationalFinanceImage,
  //   title: 'Green Finance',
  //   textLines: [
  //     'International Finance',
  //     'International Finance A Catalyst for Prosperity International Finance Corporation Injects USD 7 Billion into Egypts Economic Engine',
  //     '31 January, 2024',
  //   ],
  //   toPage: InsightsPathConstants.INTERNATIONAL_FINANCE,
  // },
  {
    img: CarbonMarket,
    title: "Carbon Market",
    textLines: [
      "Carbon Market",
      "Egypt's New Regulated Voluntary Carbon Market",
      "21 August, 2024",
    ],
    toPage: InsightsPathConstants.CARBON_MARKET,
  },
  {
    img: mainInsightsGenericInsightsRenewableImage,
    title: 'Wave and Tidal',
    textLines: [
      'COP27 & COP28',
      'Navigating the Path to Renewables: Egypt and the UAEs Energy Transition Roadmap at COP27 and COP28',
      '5 March, 2024',
    ],
    toPage: InsightsPathConstants.CONFERENCE_OF_THE_PARTIES,
  },
  {
    img: mainInsightsRenewable,
    title: 'Circular Energy',
    textLines: [
      'Renewable Energy',
      'A Nation Powered by Sunshine Egypts 2030 Renewable Energy Target',
      '28 January, 2024',
    ],
    toPage: InsightsPathConstants.RENEWABLE_ENERGY,
  },
  {
    img: mainInsightsDataPrivacyImage,
    title: 'AI',
    textLines: [
      'Data Privacy',
      'A New Dawn for Data Privacy: Unveiling Egypts Evolving Regulatory Landscape',
      '25 March, 2024',
    ],
    toPage: InsightsPathConstants.DATA_PRIVACY,
  },
  {
    img: mainInsightsLegalPerspectiveImage,
    title: 'Adaptions',
    textLines: [
      'Legal Perspective',
      'New International Arbitration Centre Launches in Abu Dhabi (ArbitrateAD)',
      '5 December, 2023',
    ],
    toPage: InsightsPathConstants.LEGAL_PERSPECTIVE,
  },
  {
    img: mainInsightsRenewableEnergyFutureImage,
    title: 'Employment',
    textLines: [
      'Renewable Energy Future',
      'CRCICA Rules 2024: Advancing International Arbitration',
      '12 December, 2023',
    ],
    toPage: InsightsPathConstants.RENEWABLE_ENERGY_FUTURE,
  },
  {
    img: mainInsightsSustainableMobilityImage,
    title: 'Infrastructure',
    textLines: [
      'Sustainable Mobility',
      'Green Transportation: Navigating the Transition to Sustainable Mobility',
      '20 February, 2024',
    ],
    toPage: InsightsPathConstants.SUSTAINABLE_MOBILITY,
  },
  {
    img: mainInsightsTaxingCatalystImage,
    title: 'Arbitration',
    textLines: [
      'Taxing Catalyst',
      'Taxation and Investment Law: A Taxing Catalyst',
      '31 January, 2024',
    ],
    toPage: InsightsPathConstants.TAXING_CATALYST,
  },
  {
    img: mainInsightsTransportationLogistics,
    title: 'Infrastructure',
    textLines: [
      'Transportation',
      'Ascending the Logistics Ladder: Egypts Strategic Transformation into a Global Transport and Logistics Hub by 2024',
      '22 December, 2023',
    ],
    toPage: InsightsPathConstants.TRANSPORTATION_LOGISTICS,
  },
  {
    img: mainInsightsMergerControlImage,
    title: 'Arbitration',
    textLines: [
      'Merger Control',
      'Reshaping the Landscape: Transformative Changes in Egypt’s Merger Control Framework',
      '22 January, 2024',
    ],
    toPage: InsightsPathConstants.MERGER_CONTROL,
  },
  {
    img: mainInsightsHydrogenInitiativesImage,
    title: 'Wave and Tidal',
    textLines: [
      'Hydrogen Initiatives',
      'Legislative Committee Approves Proposed Law on Incentives for Green Hydrogen Initiatives',
      '7 December, 2023',
    ],
    toPage: InsightsPathConstants.HYDROGEN_INITIATIVES,
  },
];
