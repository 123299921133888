import React from 'react';

const CapitalRetentionText = () => {
  return (
    <>
      <div
        style={{
          fontSize: '22px',
          fontWeight: '400',
          lineHeight: '1.2',
          
          fontFamily: 'Times New Roman, Times, serif',
          color: "#555555"
        }}>

        <p style={{ marginBottom: '10px', fontSize: 22 }}>
          <strong>Egypt’s New Opportunities: Embracing Foreign Currency Capital Retention</strong>
        </p>

        <p>
          On 16 January 2024, Egypt witnessed a significant economic milestone as the General Authority for Investment and Free Zones (GAFI) and the Central Bank of Egypt (CBE) announced a groundbreaking policy. Foreign companies operating in Egypt can now retain their capital increase deposits in foreign currency, a departure from the previous requirement for conversion to Egyptian pounds. This move, navigating legal intricacies, promises to unlock economic and business opportunities for both Egypt and its international partners.
        </p>
        <br />

        <h2><strong>A Gateway to Economic Growth</strong></h2>
        <br />

        <p>Egypt's decision aligns with renowned free zones similar to Dubai, UAE, known for attracting foreign companies with favorable tax breaks, flexible currency regulations, and minimal bureaucratic hurdles. However, Egypt's policy stands out with broader applicability across all sectors:</p>
        <br />

        <ol>
          <li>
            <strong>a) </strong>Foreign Investment Magnet: Removing the currency conversion requirement aims to attract increased foreign direct investment (FDI), particularly in strategic sectors like manufacturing and green energy, fostering sustainable economic growth.
          </li>
          <br />

          <li>
            <strong>b) </strong>Operational Agility: Companies gain flexibility by accessing their foreign currency reserves for international procurement, equipment needs, and other global obligations, streamlining operations and fostering financial planning autonomy.
          </li>
          <br />

          <li>
            <strong>c) </strong>Export Engine: Enhanced access to foreign currency empowers companies to integrate seamlessly into international supply chains, boosting Egypt's export competitiveness and generating valuable foreign exchange earnings.
          </li>
          <br />

        </ol>

        <h2><strong>Real-World Impact</strong></h2>
        <br />

        <p>This policy aligns seamlessly with Egypt's broader economic aspirations:</p>
        <br />

        <ol>
          <li>
            <strong>a) </strong>National Diversification: Diversifying from tourism and traditional sectors requires attracting foreign investment, a goal directly addressed by this policy.
          </li>
          <br />

          <li>
            <strong>b) </strong>Global Value Chain Integration: Access to foreign currency paves the way for seamless integration into international supply chains, enhancing trade partnerships and export prospects.
          </li>
          <br />

          <li>
            <strong>c) </strong>Building a Vibrant Business Landscape: The policy fosters a more attractive environment for foreign companies, boosting competition and innovation within Egypt's business ecosystem.
          </li>
          <br />

        </ol>

        <h2><strong>Legal Considerations</strong></h2>
        <br />

        <p>This policy aligns seamlessly with Egypt's broader economic aspirations:</p>
        <br />

        <ol>
          <li>
            <strong>a) </strong>Robust Compliance Framework: Clear regulations and transparent procedures for accessing and utilizing foreign currency reserves are paramount to ensure effective application and prevent misuse.
          </li>
          <br />

          <li>
            <strong>b) </strong>Tax Clarity and Efficiency: Defining the legal framework regarding taxation of foreign currency earnings and profit repatriation under this new policy is crucial to avoid ambiguities and attract investment.
          </li>
          <br />

          <li>
            <strong>c) </strong>Financial Stability Guardianship: Monitoring the influx of foreign currency and implementing potential adjustments to mitigate inflationary pressures are essential for long-term economic health.
          </li>
          <br />

        </ol>

        <p>Egypt's foreign currency capital retention policy signifies a bold step towards a more flexible and internationally connected economy. By embracing this positive shift, Egypt can unlock opportunities for foreign investment, export growth, and economic diversification. This initiative holds the potential to become a beacon of innovation for other nations seeking to attract foreign investment and thrive in the global marketplace.</p>
        <br />
      </div>
    </>
  );
};

export default CapitalRetentionText;

export const CarbonMarketText = () => {
  return (
    <div
      style={{
        fontSize: '22px',
        fontWeight: '400',
        lineHeight: '1.2',
        fontFamily: 'Times New Roman, Times, serif',
        color: "#555555"
      }}>

      <p style={{ marginBottom: '20px' }}>
      Egypt has recently launched its first regulated voluntary carbon market, an innovative initiative developed by the Financial Regulatory Authority (FRA) and the Ministry of Environment. This groundbreaking market is established in accordance with Prime Ministerial Decree No. 4664 of 2022, which recognizes carbon credits as financial instruments and mandates the creation of a specialized trading platform by the Egyptian Stock Exchange. This regulatory framework aims to create a transparent and structured environment for trading carbon reduction certificates, reinforcing Egypt’s commitment to its climate goals and enhancing its role in global carbon markets.
      </p>

      <h2 style={{ marginBottom: '20px' }}><strong>THE VOLUNTARY CARBON MARKET</strong></h2>

      <p style={{ marginBottom: '20px' }}>
      Voluntary carbon markets are platforms where carbon credits are traded to offset greenhouse gas emissions. Unlike mandatory carbon markets, which are driven by regulatory requirements, voluntary markets operate on the basis of voluntary actions taken by organizations and individuals. These markets play a crucial role in advancing global carbon reduction efforts by fostering investment in green technologies and sustainable practices. Participants, including companies and organizations, voluntarily purchase carbon credits to offset their emissions, thereby contributing to climate change mitigation and aligning their operations with broader environmental goals.
      </p>

      <h2 style={{ marginBottom: '20px' }}><strong>REGULATORY FRAMEWORK AND MARKET STRUCTURE</strong></h2>

      <p style={{ marginBottom: '20px' }}>
      Under the Prime Ministerial Decree No. 4664 of 2022, the FRA is tasked with regulating and overseeing Egypt’s voluntary carbon market. This decree establishes the legal basis for recognizing carbon credits as financial instruments and directs the Egyptian Stock Exchange to develop a dedicated trading platform for carbon credits. This platform is designed to facilitate structured transactions, ensuring transparency and adherence to strict verification standards.
      </p>

      <p style={{ marginBottom: '20px' }}>
      The FRA's regulatory role is crucial in maintaining the market's integrity. The law requires that all carbon credits meet rigorous verification criteria to ensure they represent real and measurable reductions in carbon emissions. This oversight is crucial for preserving the market's credibility and effectiveness, aligning with both national and global climate goals.
      </p>

      <h2 style={{ marginBottom: '20px' }}><strong>IMPLEMENTATION STEPS AND MARKET OPERATIONS</strong></h2>

      <p style={{ marginBottom: '20px' }}>
      Participation in Egypt’s carbon market involves a systematic process governed by the legal framework established under Prime Ministerial Decree No. 4664 of 2022. The process begins with the <strong>Project Plan</strong>, where developers confirm the basic design of their projects, estimate their climate and sustainable development impacts, and prepare a Key Project Information note summarizing these aspects. This foundational work ensures that the project is well-conceived and aligned with environmental objectives.
      <br/>
      <br/>
      The next step is <strong>Project Registration</strong>, in which developers must register their projects in one of the approved carbon registries. The project’s methodology must comply with the standards endorsed by the registry to ensure proper recognition within the market.
      <br/>
      <br/>
      Following registration, <strong>Third-Party Validation</strong> is required. This step involves obtaining a positive validation opinion from a Verification and Validation Body (VVB) and submitting a World Bank-approved Project Design Document (PDD) along with supporting documentation, confirming the project meets the necessary standards.
      <br/>
      <br/>
      The fourth step is <strong>Project Implementation</strong>, where developers execute the project according to the approved PDD. After implementation, <strong>Third-Party Verification</strong> is conducted, requiring the submission of a completed Monitoring Report and obtaining a positive verification opinion from the VVB. This verification confirms that the project has achieved the claimed emissions reductions.
      <br/>
      <br/>
      Finally, in the <strong>Credits Issuance</strong> phase, carbon credits are issued in the registry account following successful verification. Each credit represents a reduction of one ton of CO2 equivalent (CO2e), with the number of credits issued corresponding to the verified amount of CO2e reduction.
      </p>
      <h2 style={{ marginBottom: '20px' }}><strong>SUMMARY</strong></h2>
      <p style={{ marginBottom: '20px' }}>
      The establishment of Egypt’s regulated voluntary carbon market under Prime Ministerial Decree No. 4664 of 2022 offers substantial environmental and economic benefits. Environmentally, it enhances the credibility and effectiveness of carbon offsets, supporting global climate objectives. Economically, it fosters investment in green technologies and promotes sustainable growth. By integrating carbon reduction efforts into a structured financial framework, Egypt not only advances its environmental goals but also attracts international investment and strengthens its position in global climate negotiations. This initiative marks a significant step forward in aligning financial systems with environmental objectives, positioning Egypt as a leader in regional carbon markets and contributing to global climate action.
      </p>
    </div>
  );
};

export const CarbonMarketHeaderText = () => {
  return (
    <>
      <div className='mobile-headers-box seven-percent-left adjust-insight'>
          <p
            className='main-headers seven-percent-left'
            style={{
              color: 'white',
              fontSize: '50px',
              maxWidth: "600px",
              fontWeight: '400',
            }}>
            Egypt's New Regulated Voluntary {" "}
            <span
              style={{
                fontWeight: '100',
                fontStyle: 'italic',
              }}>
             Carbon Market
            </span>
          </p>
        </div>
    <h1
      className='under-1024-disapear'
      style={{
        position: 'absolute',
        color: 'white',
        top: 571,
        left: 85,
        right: 0,
        fontFamily: 'Montserrat',
        fontSize: '60px',
        fontWeight: 400,
        lineHeight: '73px',
        letterSpacing: '0em',
        textAlign: 'left',
      }}>
      Egypt's New Regulated Voluntary {" "}
      <span
        style={{
          fontFamily: 'Montserrat',
          fontSize: '60px',
          fontWeight: 100,
          fontStyle: 'italic',
          lineHeight: '73px',
          letterSpacing: '0em',
        }}>
        Carbon Market
      </span>
    </h1>
    </>
  );
};
